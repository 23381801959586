import { registerLocaleData } from '@angular/common';
import {
    provideHttpClient,
    withInterceptors,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import {
    APP_INITIALIZER,
    enableProdMode,
    importProvidersFrom,
} from '@angular/core';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
    DateFnsAdapter,
    MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import {
    bootstrapApplication,
    BrowserModule,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { TRANSLOCO_MISSING_HANDLER } from '@jsverse/transloco';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import {
    NgxsModule,
    NoopNgxsExecutionStrategy,
} from '@ngxs/store';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideEcharts } from 'ngx-echarts';
import {
    last,
} from 'ramda';

import { OAuthProvider } from '~/app/domains/authentication/services/oauth/common/oauth-provider';
import { OAuthProviderServiceFactory } from '~/app/domains/authentication/services/oauth/oauth-provider.factory';
import { ConsentProvider } from '~/app/domains/consent/services/common/consent-provider';
import { ConsentProviderServiceFactory } from '~/app/domains/consent/services/consent-provider.factory';
import { apiInterceptor } from '~/app/core/services/interceptors/api.interceptor';
import { initServicesFactory } from '~/app/core/services/init-services.factory';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { BaseConfigurationService } from '~/app/shared/services/base-configuration/base-configuration.service';
import { BaseTrackingService } from '~/app/shared/services/tracking/base-tracking.service';
import { MatomoService } from '~/app/shared/services/tracking/matomo/matomo.service';
import { Migration } from '~/app/shared/types/migration.type';
import { CustomHandler } from '~/app/shared/services/transloco/transloco-missing-handler';
import { environment } from '~/environments/environment';

import { AppComponent } from './app/app.component';
import { AuthorisationService } from '~/app/domains/authorisation/services/authorisation/authorisation.service';
import { requestInterceptor } from '~/app/core/services/interceptors/request.interceptor';
import { ApplicationState } from './app/core/state/application/application-state/application.state';
import { AuthenticationState } from './app/core/state/authentication/authentication.state';
import { AuthorisationState } from './app/core/state/authorisation/authorisation.state';
import { ConfigurationState } from './app/core/state/configuration/configuration.state';
import { ContactsDetailsState } from './app/core/state/contacts-details/contacts-details.state';
import {
    ContactsListModel,
    ContactsListState,
} from './app/core/state/contacts-list/contacts-list.state';
import { ContactsWorkspaceState } from './app/core/state/contacts-workspace/contacts-workspace.state';
import { PortfoliosDetailsState } from './app/core/state/portfolios-details/portfolios-details.state';
import {
    PortfoliosListModel,
    PortfoliosListState,
} from './app/core/state/portfolios-list/portfolios-list.state';
import { PortfoliosWorkspaceState } from './app/core/state/portfolios-workspace/portfolios-workspace.state';
import { ShareDetailsState } from './app/core/state/share-details/share-details.state';
import {
    SharesListModel,
    SharesListState,
} from './app/core/state/shares-list/shares-list.state';
import { SharesWorkspaceState } from './app/core/state/shares-workspace/shares-workspace.state';
import { SimulationHistoricalState } from './app/core/state/simulation-historical/simulation-historical.state';
import { SimulationProjectionsState } from './app/core/state/simulation-projections/simulation-projections.state';
import { SimulationScenarioState } from './app/core/state/simulation-scenario/simulation-scenario.state';
import { ROUTES } from './app/routes';
import { BasePermissionsService } from './app/shared/services/base-permissions/base-permissions.service';
import { faviconUtilsFactory } from './app/shared/services/favicon-utils/favicon-utils.factory';
import { FaviconUtilsService } from './app/shared/services/favicon-utils/favicon-utils.service';
import { LocaleProvider } from './app/shared/services/locale/locale.provider';
import { TranslocoRootModule } from '~/app/shared/services/transloco/transloco-root.module';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

if (environment.production) {
    enableProdMode();
}

const config: Migration[] = [
    {
        version: 2,
        migrate: () => {
            localStorage.removeItem('sharesWorkspace');
            localStorage.removeItem('sharesList.sharesList');
            localStorage.removeItem('sharesList.currentShareList');
            localStorage.removeItem('sharesList.defaultSortValue');
            localStorage.removeItem('portfoliosWorkspace');
            localStorage.removeItem('portfoliosList.lists');
            localStorage.removeItem('portfoliosList.currentList');
            localStorage.removeItem('portfoliosList.defaultSortValue');
        },
    },
    {
        version: 3,
        migrate: () => {
            localStorage.removeItem('portfoliosWorkspace');
            localStorage.removeItem('portfoliosList.lists');
            localStorage.removeItem('portfoliosList.currentList');
        },
    },
    {
        version: 4,
        migrate: () => {
            localStorage.removeItem('shareDetails.viewModeDisplay.PRO');
            localStorage.removeItem('shareDetails.viewModeDisplay.ADVANCED');
            localStorage.removeItem('shareDetails.viewModeDisplay.MAIN');
            localStorage.removeItem('simulationHistorical.viewModeDisplay');
        },
    },
    {
        version: 5,
        migrate: () => {
            localStorage.removeItem('sharesWorkspace');
            localStorage.removeItem('sharesList.sharesList');
            localStorage.removeItem('sharesList.currentShareList');
        },
    },
    {
        version: 6,
        migrate: () => {
            localStorage.removeItem('portfoliosWorkspace');
            localStorage.removeItem('portfoliosList.lists');
            localStorage.removeItem('portfoliosList.currentList');
        },
    },
    {
        version: 7,
        migrate: () => {
            localStorage.removeItem('sharesWorkspace');
            localStorage.removeItem('sharesList.sharesList');
            localStorage.removeItem('sharesList.currentShareList');
            localStorage.removeItem('sharesList.defaultDisplayValues');
        },
    },
    {
        version: 8,
        migrate: () => {
            localStorage.removeItem('sharesWorkspace');
            localStorage.removeItem('sharesList.defaultDisplayValues');
            localStorage.removeItem('sharesList.sharesList');
            localStorage.removeItem('sharesList.currentShareList');
            localStorage.removeItem('portfoliosWorkspace');
            localStorage.removeItem('portfoliosList.lists');
            localStorage.removeItem('portfoliosList.currentList');
            localStorage.removeItem('application.isFirstVisit');
        },
    },
    {
        version: 9,
        migrate: () => {
            localStorage.removeItem('sharesWorkspace');
            localStorage.removeItem('sharesList.defaultDisplayValues');
            localStorage.removeItem('sharesList.sharesList');
            localStorage.removeItem('sharesList.currentShareList');
        },
    },
    {
        version: 10,
        migrate: () => {
            localStorage.removeItem('sharesWorkspace');
            localStorage.removeItem('sharesList.defaultDisplayValues');
            localStorage.removeItem('sharesList.sharesList');
            localStorage.removeItem('sharesList.currentShareList');
        },
    },
    {
        version: 11,
        migrate: () => {
            localStorage.removeItem('sharesList.defaultDisplayValues');
        },
    },
];

function compare(a: Migration, b: Migration) {
    if (a.version < b.version) {
        return -1;
    }
    if (a.version > b.version) {
        return 1;
    }
    return 0;
}

let version = localStorage.getItem('version') ? parseInt(localStorage.getItem('version') as string, 10) : null;
if (!version) {
    if (!localStorage.getItem('authentication')) {
        version = last(config.sort(compare))?.version ?? 1;
    } else {
        version = 1;
    }
}

config
    .sort(compare)
    .filter((migration) => migration.version > (version as number))
    .forEach((migration) => {
        migration.migrate();
        version = migration.version;
    });

localStorage.setItem('version', version.toString());

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            TranslocoRootModule,
            NgxsModule.forRoot([
                ApplicationState,
                ConfigurationState,
                AuthenticationState,
                AuthorisationState,
                ContactsListState,
                ContactsDetailsState,
                ContactsWorkspaceState,
                PortfoliosDetailsState,
                PortfoliosListState,
                PortfoliosWorkspaceState,
                SimulationProjectionsState,
                SimulationHistoricalState,
                SimulationScenarioState,
                SharesListState,
                ShareDetailsState,
                SharesWorkspaceState,
            ], {
                developmentMode: !environment.production,
                executionStrategy: NoopNgxsExecutionStrategy,
            }),
            NgxsLoggerPluginModule.forRoot({
                disabled: true,
            }),
            NgxsRouterPluginModule.forRoot(),
            NgxsStoragePluginModule.forRoot({
                keys: [
                    'version',
                    'application.isFreemiumOnboardingVisible',
                    'application.operations',
                    'application.simulationTopFundsLatestIndicator',
                    'applicationConfiguration',
                    'application.lastCheckSessionDate',
                    'application.portfolioAllocation',
                    'authentication',
                    'contactsList.lists',
                    'contactsList.currentList',
                    'contactsWorkspaces',
                    'sharesList.defaultDisplayValues',
                    'sharesList.sharesList',
                    'sharesList.currentShareList',
                    'sharesList.currentSavedSearchId',
                    'shareDetails.viewModeDisplay.MAIN',
                    'shareDetails.viewModeDisplay.PRO',
                    'shareDetails.viewModeDisplay.ADVANCED',
                    'shareDetails.params.viewMode',
                    'sharesWorkspace',
                    'portfoliosList.lists',
                    'portfoliosList.currentList',
                    'portfoliosList.defaultDisplayValues',
                    'portfoliosWorkspace',
                    'portfoliosDetails.createForm',
                    'portfoliosDetails.error',
                    'portfoliosDetails.portfolioState',
                    'portfoliosDetails.allocationChangeType',
                    'portfoliosDetails.portfolio',
                    'portfoliosDetails.viewModeDisplay',
                    'portfoliosDetails.params.viewMode',
                    'portfoliosDetails.assetFields',
                    'simulationProjections.draft.params.portfolioIds',
                    'simulationProjections.draft.params.allocations',
                    'simulationProjections.draft.params.creatingPortfolio',
                    'simulationProjections.arbitrages',
                    'simulationProjections.viewModeDisplay',
                    'simulationProjections.viewMode',
                    'simulationHistorical.draft',
                    'simulationHistorical.active',
                    'simulationHistorical.simulation',
                    'simulationHistorical.requestId',
                    'simulationHistorical.viewModeDisplay',
                    'simulationHistorical.viewMode',
                    'simulationScenario.active',
                    'simulationScenario.draft',
                    'simulationScenario.editedScenario',
                    'simulationScenario.marketEventDisplayMode',
                    'simulationScenario.requestId',
                    'simulationScenario.simulation',
                    'simulationScenario.viewModeDisplay',
                    'simulationScenario.viewMode',
                    'simulationScenario.activeScenario',
                ],
                beforeSerialize: (obj, key) => {
                    if (key === 'sharesList.sharesList') {
                        const currentObj = obj as Record<string, SharesListModel>;
                        return {
                            ...currentObj,
                            ...(Object.entries(currentObj).reduce((acc, [prop, item]) => ({
                                ...acc,
                                [prop]: {
                                    ...item,
                                    shares: {
                                        size: 20,
                                        totalNumber: 0,
                                        startAt: 0,
                                        requestId: '',
                                        values: [],
                                    },
                                    query: '',
                                    indicatorsIsLoading: {},
                                    isLoading: false,
                                    isLoadingPromoted: false,
                                    trendingShares: [],
                                    promotedShares: [],
                                },
                            }), {})),
                        };
                    }
                    if (key === 'portfoliosList.lists') {
                        const currentObj = obj as Record<string, PortfoliosListModel>;
                        return {
                            ...currentObj,
                            ...(Object.entries(currentObj).reduce((acc, [prop, item]) => ({
                                ...acc,
                                [prop]: {
                                    ...item,
                                    portfolios: {
                                        size: 20,
                                        totalNumber: 0,
                                        startAt: 0,
                                        requestId: '',
                                        values: [],
                                    },
                                    isLoading: false,
                                },
                            }), {})),
                        };
                    }
                    if (key === 'contactsList.lists') {
                        const currentObj = obj as Record<string, ContactsListModel>;
                        return {
                            ...currentObj,
                            ...(Object.entries(currentObj).reduce((acc: Record<string, ContactsListModel>, [prop, item]) => ({
                                ...acc,
                                [prop]: {
                                    ...item,
                                    contacts: {
                                        size: 20,
                                        totalNumber: 0,
                                        startAt: 0,
                                        requestId: '',
                                        values: [],
                                    },
                                    isLoading: false,
                                },
                            }), {})),
                        };
                    }
                    return obj as unknown;
                },
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                name: 'Envestboard',
                disabled: environment.production,
            }),
        ),
        provideEcharts(),
        provideAngularSvgIcon(),
        {
            provide: TRANSLOCO_MISSING_HANDLER,
            useClass: CustomHandler,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initServicesFactory,
            deps: [AuthenticationFacade, ConfigurationFacade],
            multi: true,
        },
        AuthenticationFacade,
        {
            provide: BasePermissionsService,
            useClass: AuthorisationService,
        },
        {
            provide: BaseConfigurationService,
            useClass: ConfigurationFacade,
        },
        {
            provide: OAuthProvider,
            useFactory: OAuthProviderServiceFactory,
        },
        {
            provide: ConsentProvider,
            useFactory: ConsentProviderServiceFactory,
        },
        {
            provide: BaseTrackingService,
            useClass: MatomoService,
        },
        // {
        //     provide: ErrorHandler,
        //     useValue: Sentry.createErrorHandler({
        //         showDialog: false,
        //     }),
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: faviconUtilsFactory,
            deps: [FaviconUtilsService, ConfigurationFacade],
            multi: true,
        },
        LocaleProvider,
        {
            provide: DateAdapter,
            useClass: DateFnsAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_DATE_FNS_FORMATS,
        },
        provideAnimations(),
        provideHttpClient(
            withInterceptors([
                requestInterceptor,
                apiInterceptor,
            ]),
        ),
        provideRouter(ROUTES),
    ],
})

    .catch((err) => console.error(err));
