import { DOCUMENT } from '@angular/common';
import {
    inject,
    Injectable,
    Renderer2,
    RendererFactory2,
} from '@angular/core';

import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { BaseTrackingService } from '~/app/shared/services/tracking/base-tracking.service';
import { MatomoTrackingModel } from '~/app/shared/types/envestboard-api';
import { MatomoTrackingEvent } from '~/app/shared/types/tracking/matomo-event.type';
import { TrackingEventContext } from '~/app/shared/types/tracking/tracking-event-context.type';

const MAIN_SCRIPT_ID = 'mtm_main_script';

 
declare const _mtm: {
    push: (args: any) => void,
};

@Injectable({
    providedIn: 'root',
})
export class MatomoService extends BaseTrackingService {
    private rendererFactory2 = inject(RendererFactory2);

    private document = inject(DOCUMENT);

    private configurationFacade = inject(ConfigurationFacade);

    public generateNewTrackEvent(
        args: TrackingEventContext,
    ): MatomoTrackingEvent {
        return {
            event: 'trackEvent',
            trackEventContext: { ...args },
        };
    }

    insertScript(trackingSettings: MatomoTrackingModel | undefined): void {
        const renderer2 = this.rendererFactory2.createRenderer(null, null);
        if (!trackingSettings?.extras?.matomoUrl || !trackingSettings?.extras?.environmentUri || !trackingSettings?.siteId) {
             
            console.error('%c TRACKING Error', 'background: red; color: white');
            return;
        }
        const uriMatomoScript = `${trackingSettings.extras.matomoUrl}/${trackingSettings?.extras?.environmentUri
            ?.replace('{SITE_ID}', atob(trackingSettings?.siteId))}`;
        const nodeContent = `var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        (function() {
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='${uriMatomoScript}'; s.parentNode.insertBefore(g,s);
        })();
        `;
        const script = this.createOrOverrideScriptNode(renderer2, MAIN_SCRIPT_ID, nodeContent);
        renderer2.appendChild(this.document.head, script);
    }

    public pushEvent(trackingEvent: TrackingEventContext) {
        if (this.configurationFacade.getIsMatomoTrackingEnabledSnapshot()) {
            const matomoTrackingEvent = this.generateNewTrackEvent(trackingEvent);
            _mtm.push(matomoTrackingEvent);
        }
    }

    public pageView() {
        if (this.configurationFacade.getIsMatomoTrackingEnabledSnapshot()) {
            _mtm.push({ event: 'PageView' });
        }
    }

    public updateUserId(userId: string | number) {
        if (this.configurationFacade.getIsMatomoTrackingEnabledSnapshot()) {
            _mtm.push({ uid: userId });
        }
    }

    private createOrOverrideScriptNode(renderer2: Renderer2, nodeId: string, nodeContent: string) {
        const scriptNode = this.document.getElementById(nodeId);
        if (scriptNode) {
            scriptNode.remove();
        }
        const script = renderer2.createElement('script') as HTMLScriptElement;
        script.setAttribute('id', nodeId);
        script.text = nodeContent;
        return script;
    }
}
