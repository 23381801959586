import {
    inject,
    Injectable,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    Observable,
    of as observableOf,
} from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

import { AuthenticationFacade } from '../../../core/state/authentication/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard {
    private router = inject(Router);

    private authFacade = inject(AuthenticationFacade);

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.getUserData().pipe(
            switchMap((hasToken) => observableOf(hasToken)),
            catchError(() => observableOf(false)),
        );
    }

    public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.getUserData().pipe(
            switchMap((hasToken) => observableOf(hasToken)),
            catchError(() => observableOf(false)),
        );
    }

    public getUserData(): Observable<any> {
        return this.authFacade.token$
            .pipe(
                tap((token: string | null | undefined) => {
                    if (!token) {

                        this.router.navigate(['/auth/login'], { queryParams: { redirect: this.router.url } });
                    }
                }),
                map((token: string | null | undefined) => typeof token === 'string'),
                take(1),
            );
    }
}
