import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
    CanActivateFn,
} from '@angular/router';

import { OAuthProvider } from '~/app/domains/authentication/services/oauth/common/oauth-provider';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { AuthenticationModelType } from '~/app/shared/types/envestboard-api';

export const ssoGuard: CanActivateFn = (route) => {
    const configurationFacade = inject(ConfigurationFacade);
    if (configurationFacade.getAuthenticationSnapshot()?.type === AuthenticationModelType.OAUTH) {
        const authenticationFacade = inject(AuthenticationFacade);
        const provider = inject(OAuthProvider);
        if (!authenticationFacade.getTokenSnapshot()) {
            // const oauthProvider = inject(OAuthProvider);
            const document = inject(DOCUMENT);
            const oauthConfiguration = configurationFacade.getAuthenticationSnapshot()?.oauthConfiguration;
            // @ts-ignore

            if (!oauthConfiguration
                || !oauthConfiguration.redirectUrl
                || !oauthConfiguration.loginUrl
                || !oauthConfiguration.clientId
                || !oauthConfiguration.scope
                || !oauthConfiguration.authorityUrl) {
                return false;
            }

            // @ts-ignore

            document.location.href = provider.getLoginUrl(route.queryParams.accountServicerId);
            return false;
        }
    }
    return true;
};
