import { HttpClient } from '@angular/common/http';
import {
    inject,
    Injectable,
} from '@angular/core';
import {
    Translation,
    TranslocoLoader,
} from '@jsverse/transloco';
import { map } from 'rxjs';

import cacheBustingSource from '../../../../../i18n-cache-busting.json';
import { TranslationService } from '../../../core/services/api/translation/translation.service';

const cacheBusting = cacheBustingSource as Record<string, string>;

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);

    private translationService = inject(TranslationService);

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`);
    }

    getTranslationFromAPI(lang: string) {
        return this.translationService.getTranslations(lang).pipe(
            map((overwrittenTranslations) => overwrittenTranslations?.[lang] ?? {}),
        );
    }
}
