import { Route } from '@angular/router';

import { PERMISSIONS } from '~/app/domains/authorisation/constants/permissions.constants';
import { AuthenticationGuard } from '~/app/domains/authentication/routing/authentication.guard';
import { PublicAuthenticationGuard } from '~/app/domains/authentication/routing/public-authentication.guard';
import { ssoGuard } from '~/app/domains/authentication/routing/sso.guard';
import { AuthorisationGuard } from '~/app/domains/authorisation/routing/authorisation.guard';
import { checkFeatureEnabledGuard } from '~/app/routing/guards/check-feature-enabled/check-feature-enabled.guard';
import { GuestGuard } from '~/app/routing/guards/guest/guest.guard';
import { initGuard } from '~/app/routing/guards/init/init.guard';
import { initUnauthenticatedGuard } from '~/app/routing/guards/init-unauthenticated/init-unauthenticated.guard';
import { MainLayoutComponent } from '~/app/layouts/main-layout/main-layout.component';
import { ErrorComponent } from '~/app/shared/components/layouts/not-found/error.component';
import { FeatureFlag } from './domains/configuration/enums/feature-flag.enum';

export const ROUTES: Route[] = [
    {
        path: '',
        redirectTo: 'shares',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('~/app/domains/authentication/routes'),
        canActivate: [
            initUnauthenticatedGuard,
            GuestGuard,
        ],
    },
    {
        path: 'callback',
        loadChildren: () => import('~/app/domains/consent/routing/routes'),
    },
    {
        path: '',
        canActivate: [ssoGuard],
        children: [
            {
                path: '',
                component: MainLayoutComponent,
                canActivate: [
                    initGuard,
                ],
                children: [
                    {
                        path: '',
                        canActivate: [PublicAuthenticationGuard],
                        children: [
                            {
                                path: 'shares',
                                loadChildren: () => import('~/app/domains/shares/routes'),
                            },
                            {
                                path: 'profile',
                                loadComponent: () => import('~/app/domains/profile/features/profile.component')
                                    .then(m => m.ProfileComponent),
                            },
                        ],
                    },
                    {
                        path: '',
                        canActivate: [AuthenticationGuard],
                        children: [
                            {
                                path: 'contacts',
                                loadChildren: () => import('~/app/domains/contacts/routes'),
                            },
                            {
                                path: 'portfolios',
                                loadChildren: () => import('~/app/domains/portfolios/routes'),
                                data: {
                                    featureFlag: FeatureFlag.TECH_FRONT_PORTFOLIO_ENABLE,
                                    permissions: [PERMISSIONS.PORTFOLIO_MODULE_ACCESS],
                                },
                                canActivate: [checkFeatureEnabledGuard, AuthorisationGuard],
                            },
                        ],
                    },

                ],
            },
        ],
    },
    {
        path: 'unauthorised',
        data: {
            errorCode: '403',
        },
        component: ErrorComponent,
    },
    {
        path: '**',
        component: ErrorComponent,
    },
];
