import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    EMPTY,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ValidPhoneNumberComponent } from '~/app/shared/modals/valid-phone-number/valid-phone-number.component';
import { AuthenticationService } from '~/app/core/services/api/authentication/authentication.service';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AlertComponent } from '~/app/shared/components/elements/alert/alert.component';
import { ButtonComponent } from '~/app/shared/components/elements/buttons/button/button.component';
import { H2Component } from '~/app/shared/components/elements/h2/h2.component';
import { LabelComponent } from '~/app/shared/components/elements/label/label.component';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import { ModalService } from '~/app/shared/services/modal/modal.service';
import { ResumeFeatureContext } from '~/app/shared/types/resume-feature-context.type';

export type ModalEndFreetrialInputArgs = {
    context: ResumeFeatureContext | undefined,
}

@UntilDestroy()
@Component({
    selector: 'eb-end-freetrial',
    templateUrl: './end-freetrial.component.html',
    styleUrls: ['./end-freetrial.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        H2Component,
        AlertComponent,
        LabelComponent,
        ButtonComponent,
        TranslocoPipe,
    ],
})
export class EndFreetrialComponent implements OnDestroy {
    public loading = false;

    public success = false;

    public error = false;

    public errorMsg = '';

    public modalRef: ModalRef | null = null;

    constructor(
        private authenticationService: AuthenticationService,
        private authenticationFacade: AuthenticationFacade,
        private modal: ModalService,
        private cd: ChangeDetectorRef,
        public ref: ModalRef<{}, ModalEndFreetrialInputArgs>,
    ) {}

    public ngOnDestroy(): void {
        this.modalRef?.dispose();
    }

    public close() {
        this.ref.close({});
    }

    public contactMe() {
        const user = this.authenticationFacade.getUserSnapshot();

        if (!user?.phoneVerified) {
            this.modalRef = this.modal.open(
                ValidPhoneNumberComponent,
                {
                    sessionCount: 0,
                },
                {
                    padding: true,
                    isOutsideClickCloseModal: true,
                    closable: true,
                },
            );

            this.modalRef.afterClosed$
                .pipe(
                    untilDestroyed(this),
                )
                .subscribe((result) => {
                    if (result) this.contactMe();
                });
        } else {
            this.loading = true;

            this.authenticationService.recallMe({}).pipe(
                catchError((error: HttpErrorResponse) => {
                    this.loading = false;

                    this.errorMsg = error.error?.message ?? error.error?.detail;
                    this.error = true;
                    this.cd.detectChanges();
                    return EMPTY;
                }),
                untilDestroyed(this),
            ).subscribe(() => {
                this.loading = false;
                this.success = true;
                this.cd.detectChanges();
                setTimeout(() => {
                    this.ref.close({});
                }, 5000);
            });
        }
    }
}
