
import { inject } from '@angular/core';

import { OauthNatixisProvider } from '~/app/domains/authentication/services/oauth/natixis/oauth-natixis-provider';
import { OauthTapicoProvider } from '~/app/domains/authentication/services/oauth/tapico/oauth-tapico-provider';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { ProviderOAuthType } from '~/app/domains/authentication/enums/authentication-provider.enum';

export const providerLibrary = {
    [ProviderOAuthType.NATIXIS]: (
    ): OauthNatixisProvider => new OauthNatixisProvider(),
    [ProviderOAuthType.TAPICO]: (
    ): OauthTapicoProvider => new OauthTapicoProvider(),
    [ProviderOAuthType.NONE]: (
    ): OauthNatixisProvider => new OauthNatixisProvider(),
};

const getProvider = (
    configurationFacade: ConfigurationFacade,
) => {
    const providerName = (configurationFacade?.getAuthenticationSnapshot()?.oauthConfiguration?.provider ?? 'NONE') as ProviderOAuthType;
    return providerLibrary[providerName]();
};

export const OAuthProviderServiceFactory = (
) => {
    const configurationFacade = inject(ConfigurationFacade);
    return getProvider(configurationFacade);
};
