import { inject } from '@angular/core';
import { uid } from 'uid';

import { OAuthProvider } from '~/app/domains/authentication/services/oauth/common/oauth-provider';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { ProviderOAuthType } from '~/app/domains/authentication/enums/authentication-provider.enum';


export class OauthNatixisProvider extends OAuthProvider {
    public configurationFacade: ConfigurationFacade = inject(ConfigurationFacade);

    constructor() {
        super(ProviderOAuthType.NATIXIS);
    }

    getLogoutUrl(isUserInternal: boolean): string {
        const oauthConfiguration = this.configurationFacade.getAuthenticationSnapshot()?.oauthConfiguration;
        if (!oauthConfiguration
            || !oauthConfiguration?.authorityUrl
            || !oauthConfiguration?.clientId
            || !oauthConfiguration?.internalLogoutUrl
            || !oauthConfiguration?.externalLogoutUrl
        ) {
            return '/auth/login';
        }
        if (isUserInternal) {
            return `${oauthConfiguration?.authorityUrl}/v2/logout?client_id=${oauthConfiguration?.clientId}&returnTo=${oauthConfiguration?.internalLogoutUrl}`;
        }
        return `${oauthConfiguration?.authorityUrl}/v2/logout?client_id=${oauthConfiguration?.clientId}&returnTo=${oauthConfiguration?.externalLogoutUrl}`;
    }

    getLoginUrl(): string {
        const authenticationConfiguration = this.configurationFacade.getAuthenticationSnapshot();
        if (authenticationConfiguration
            && authenticationConfiguration.oauthConfiguration?.clientId
            && authenticationConfiguration.oauthConfiguration?.redirectUrl
        ) {
            const url = new URL(`${authenticationConfiguration.oauthConfiguration?.authorityUrl}/authorize`);
            url.searchParams.append('client_id', authenticationConfiguration.oauthConfiguration?.clientId);
            url.searchParams.append('scope', 'openid profile email');
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('redirect_uri', authenticationConfiguration.oauthConfiguration?.redirectUrl);
            url.searchParams.append('state', uid());
            return url.toString();
        }

        console.warn('provider login url configuration incomplete');
        return '/auth/login';
    }
}
