import {
    inject,
    Injectable,
} from '@angular/core';

import { EndFreetrialComponent } from '~/app/domains/authorisation/modals/end-freetrial/end-freetrial.component';
import { ResumeFeatureService } from '~/app/domains/authorisation/services/resume-feature/resume-feature.service';
import { PERMISSIONS_PARAMS } from '~/app/domains/authorisation/constants/permissions-params.constants';
import { BRONZE_PERMISSION } from '~/app/domains/authorisation/constants/permissions.constants';
import {
    MINIMUM_CATEGORIES_TO_ALLOW_TOP_FUNDS,
} from '~/app/domains/portfolios/constants/top-fund.constants';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { FeatureFlag } from '~/app/domains/configuration/enums/feature-flag.enum';
import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { BasePermissionsService } from '~/app/shared/services/base-permissions/base-permissions.service';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import { ModalService } from '~/app/shared/services/modal/modal.service';
import { PermissionModel } from '~/app/shared/types/api/authorisation/permission-model.type';
import { PermissionParam } from '~/app/domains/authorisation/types/permission-param.type';
import { BinaryOperator } from '~/app/shared/types/binary-operator.type';

import { ModalLockedFeatureMode } from '~/app/domains/authorisation/components/modal-locked-feature/modal-locked-feature.component';
import {
    SEARCH_UNIVERSE_PERMISSIONS_PARAMS
} from '~/app/domains/authorisation/enums/params/search-universe-permissions-params.enum';
import { MINIMUM_SEARCH_UNIVERSE_ELEMENT } from '~/app/domains/shares/constants/search_universe.constants';

@Injectable()
export class AuthorisationService extends BasePermissionsService {
    private defaultIsAuthorisedValue = true;

    private authenticationFacade: AuthenticationFacade = inject(AuthenticationFacade);

    private authorisationFacade: AuthorisationFacade = inject(AuthorisationFacade);

    private resumeFeatureService = inject(ResumeFeatureService);

    private configurationFacade: ConfigurationFacade = inject(ConfigurationFacade);

    private modal: ModalService = inject(ModalService);

    private modalRef: ModalRef | null = null;

    private freeTrialPermissionsKeys = BRONZE_PERMISSION.reduce((acc: string[], perm: PermissionModel) => ([...acc, perm.key]), []);

    public hasPermissions(
        permissions: string[],
        permissionParams: PermissionParam[] | null = null,
        binaryOperator: BinaryOperator = 'AND',
    ): boolean {
        if (!this.configurationFacade.getFeatureFlagByFeatureFlagNameSnapshot(FeatureFlag.TECH_FRONT_AUTHORISATION_ENABLE)
            || !permissions
            || permissions?.length === 0
        ) {
            return this.defaultIsAuthorisedValue;
        }

        const storedPermissionsKeys = this.authorisationFacade.getAuthorisationPermissionsKeysSnapshot();
        const permissionParamsChecked: boolean[] = permissionParams && permissionParams?.length > 0
            ? permissionParams.map(((permissionParam) => this.isParamAuthorised(permissionParam)))
            : [];
        const permissionsChecked = binaryOperator === 'AND'
            ? (permissions ?? []).every((expectedPermissionKey) => storedPermissionsKeys?.includes(expectedPermissionKey))
            : (permissions ?? []).some((expectedPermissionKey) => storedPermissionsKeys?.includes(expectedPermissionKey));

        return [...permissionParamsChecked, permissionsChecked].every((validation) => validation === true);
    }

    public onLockedFeatureClick(context: RESUME_FEATURES | null, permissions: string[] = [], extraInfoKey: string | null) {
        const licence = this.authorisationFacade.getPlanSnapshot();
        if (!this.authenticationFacade.getFreeTrialUsedSnapshot() || !licence?.isFreemium) {
            const isAuthenticated = this.authenticationFacade.getIsAuthenticatedSnapshot();

            let lockedFeatureMode: ModalLockedFeatureMode = ModalLockedFeatureMode.UPGRADE_PLAN;

            if (context) {
                const permsIsInFreetrial = permissions.every((perm) => this.freeTrialPermissionsKeys.includes(perm));
                if (!isAuthenticated) {
                    lockedFeatureMode = ModalLockedFeatureMode.UPGRADE_TO_FREEMIUM;
                } else if (
                    isAuthenticated
                    && licence?.isFreemium
                    && permsIsInFreetrial
                ) {
                    lockedFeatureMode = ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL;
                } else {
                    lockedFeatureMode = licence?.isFreemium
                        ? ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL_OR_PLAN
                        : ModalLockedFeatureMode.UPGRADE_PLAN;
                }
            } else {
                lockedFeatureMode = ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL;
            }

            if (context) {
                this.modalRef = this.resumeFeatureService.openLockedFeatureModal(lockedFeatureMode, context, extraInfoKey);
            } else {
                this.modalRef = this.resumeFeatureService.openLockedFeatureModalByContext(lockedFeatureMode, false);
            }
        } else {
            const trialContext = {
                featureLocation: '',
                featureNameKey: `authorisation.feature.${context}.name`.toLowerCase(),
                featureDescriptionKey: `authorisation.feature.${context}.description`.toLowerCase(),
                ...(extraInfoKey ? {
                    featureExtraInfo: extraInfoKey,
                } : {}),
            };
            this.modalRef = this.modal.open(
                EndFreetrialComponent,
                { context: trialContext },
            );
        }
    }

    public onDispose(): void {
        if (this.modalRef) {
            this.modalRef.dispose();
        }
    }

    public isNBSimulationHistoricalProcessedStrictlyInferiorToMaxAllowed(value: number | null) {
        const maxProcessingAuthorised = this.authorisationFacade.getSimulationHistoricalNumberSimulationMaxSnapshot();
        // if back return null, we consider there is no limitation
        if (!maxProcessingAuthorised) {
            return true;
        }
        return this.isStrictlyInferior(value, maxProcessingAuthorised);
    }

    public isSimulationScenarioProcessMaxAllowed(value: number | null) {
        const maxProcessingAuthorised = this.authorisationFacade.getSimulationScenarioNumberSimulationMaxSnapshot();
        // if back return null, we consider there is no limitation
        if (!maxProcessingAuthorised) {
            return true;
        }
        return this.isStrictlyInferior(value, maxProcessingAuthorised);
    }

    private isParamAuthorised({ key, value }: PermissionParam): boolean {
        switch (key) {
            case PERMISSIONS_PARAMS.SIMULATION_SCENARIO_ELEMENT_MAX_PARAMS:
                return this.isSimulationScenarioElementMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_SCENARIO_ALLOCATION_MAX_PARAMS:
                return this.isSimulationScenarioAllocationMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_SCENARIO_EVENT_MAX_PARAMS:
                return this.isSimulationScenarioEventMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_SCENARIO_PROCESS_MAX_PARAMS:
                return this.isSimulationScenarioProcessMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_SAVING_PLAN_ELEMENT_MAX_PARAMS:
                return this.isSimulationProjectionElementMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_ELEMENT_MAX_PARAMS:
                return this.isSimulationHistoricalElementMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_ALLOCATION_MAX_PARAMS:
                return this.isSimulationHistoricalAllocationMaxAllowed(value);
            case PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_PROCESS_MAX_PARAMS:
                return this.isNBSimulationHistoricalProcessedStrictlyInferiorToMaxAllowed(value);
            case PERMISSIONS_PARAMS.FUND_SEARCH_EXCEL_FIELD_MAX_PARAMS:
                return this.isFundSearchExcelFieldMaxAllowed(value);
            case PERMISSIONS_PARAMS.FUND_SEARCH_EXCEL_FUND_MAX_PARAMS:
                return this.isFundSearchExcelFundMaxAllowed(value);
            case PERMISSIONS_PARAMS.TOP_FUNDS_CATEGORIES_ELEMENT_MAX_PARAMS:
                return this.isTopFundCategoriesElementMaxAllowed(value);
            case PERMISSIONS_PARAMS.SEARCH_UNIVERSE_ELEMENT_MAX_PARAMS:
                return this.isSearchUniverseElementMaxAllowed(value);
            default:
                return this.defaultIsAuthorisedValue;
        }
    }

    private isSimulationScenarioElementMaxAllowed(value: number | null) {
        const maxElemAuthorised = this.authorisationFacade.getSimulationScenarioElementMaxSnapshot();
        return this.isInferiorOrEqual(value, maxElemAuthorised);
    }

    private isSimulationScenarioAllocationMaxAllowed(value: number | null) {
        const maxAllocationAuthorised = this.authorisationFacade.getSimulationScenarioAllocationMaxSnapshot();
        return this.isInferiorOrEqual(value, maxAllocationAuthorised);
    }

    private isSimulationScenarioEventMaxAllowed(value: number | null) {
        const maxEventAuthorised = this.authorisationFacade.getSimulationScenarioEventMaxSnapshot();
        return this.isInferiorOrEqual(value, maxEventAuthorised);
    }

    private isSimulationHistoricalElementMaxAllowed(value: number | null) {
        const maxElemAuthorised = this.authorisationFacade.getSimulationHistoricalElementMaxSnapshot();
        return this.isInferiorOrEqual(value, maxElemAuthorised);
    }

    private isSimulationHistoricalAllocationMaxAllowed(value: number | null) {
        const maxAllocationAuthorised = this.authorisationFacade.getSimulationHistoricalAllocationMaxSnapshot();
        return this.isInferiorOrEqual(value, maxAllocationAuthorised);
    }


    private isFundSearchExcelFundMaxAllowed(value: number | null) {
        const maxFundAuthorised = this.authorisationFacade.getFundSearchExcelFundMaxSnapshot();
        return this.isInferiorOrEqual(value, maxFundAuthorised);
    }

    private isFundSearchExcelFieldMaxAllowed(value: number | null) {
        const maxFieldAuthorised = this.authorisationFacade.getFundSearchExcelFieldMaxSnapshot();
        return this.isInferiorOrEqual(value, maxFieldAuthorised);
    }

    private isSimulationProjectionElementMaxAllowed(value: number | null) {
        const maxAllocationAuthorised = this.authorisationFacade.getProjectionElementMaxSnapshot();
        return this.isInferiorOrEqual(value, maxAllocationAuthorised);
    }

    private isTopFundCategoriesElementMaxAllowed(value: number | null) {
        const maxFundAuthorised = this.authorisationFacade.getTopFundCategoriesElementMaxSnapshot();
        return this.isInferiorOrEqual(value ?? MINIMUM_CATEGORIES_TO_ALLOW_TOP_FUNDS, maxFundAuthorised);
    }

    private isSearchUniverseElementMaxAllowed(value: number | null) {
        const maxSearchElementAuthorised = this.authorisationFacade.getSearchUniverseElementMaxSnapshot();
        return this.isInferiorOrEqual(value ?? MINIMUM_SEARCH_UNIVERSE_ELEMENT, maxSearchElementAuthorised);
    }

    private isInferiorOrEqual(valueToCheck: number | undefined | null, referentialValue: number) {
        if (typeof valueToCheck === 'undefined' || valueToCheck == null) {
            return true;
        }
        return valueToCheck <= referentialValue;
    }

    private isStrictlyInferior(valueToCheck: number | undefined | null, referentialValue: number) {
        if (typeof valueToCheck === 'undefined' || valueToCheck == null) {
            return true;
        }
        return valueToCheck < referentialValue;
    }
}
