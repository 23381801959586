import {
    inject,
    Injectable,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

import { AuthenticationService } from '~/app/core/services/api/authentication/authentication.service';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';

import { AuthenticationFacade } from '../../../core/state/authentication/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class PublicAuthenticationGuard {
    private router = inject(Router);

    private authenticationFacade = inject(AuthenticationFacade);

    private configurationFacade = inject(ConfigurationFacade);

    private authenticationService = inject(AuthenticationService);

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.getUserData().pipe(
            switchMap((hasToken) => of(hasToken)),
            catchError(() => of(false)),
        );
    }

    public getUserData(): Observable<any> {
        if (this.configurationFacade.getAuthenticationSnapshot()?.guestAllowed === true) {
            if (this.authenticationFacade.getTokenSnapshot() && !this.authenticationFacade.getUserNameSnapshot()) {
                return this.authenticationService.user().pipe(switchMap((user) => {
                    this.authenticationFacade.updateUser(user);
                    return of(true);
                }));
            }
            return of(true);
        }
        return this.authenticationFacade.token$
            .pipe(
                tap((token: string | null | undefined) => {
                    if (!token) {

                        this.router.navigate(['/auth/login'], { queryParams: { redirect: this.router.url } });
                    }
                }),
                map((token: string | null | undefined) => typeof token === 'string'),
                take(1),
            );
    }
}
