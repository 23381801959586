import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Observable,
    of as observableOf,
} from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

import { AuthenticationFacade } from '../../../core/state/authentication/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class GuestGuard {
    public constructor(private router: Router, private authFacade: AuthenticationFacade) {}

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.router.getCurrentNavigation()?.extras?.state?.bypassGuardAndResolver) {
            return true;
        }
        return this.getUserData().pipe(
            switchMap((missingToken) => observableOf(missingToken)),
            catchError(() => observableOf(false)),
        );
    }

    public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.getUserData().pipe(
            switchMap(() => observableOf(true)),
            catchError(() => observableOf(false)),
        );
    }

    public getUserData(): Observable<any> {
        return this.authFacade.token$
            .pipe(
                tap((token: string | null | undefined) => {
                    if (token) {

                        this.router.navigate(['/']);
                    }
                }),
                map((token: string | null | undefined) => !token),
                take(1),
            );
    }
}
