import {
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import {
    Observable,
} from 'rxjs';

import { ApplicationConfiguration } from '~/app/domains/configuration/types/application-configuration.type';
import { environment } from '~/environments/environment';

export function apiInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    if (
        req.url.endsWith('.html')
        || req.url.includes('.json')
        || req.url.endsWith('.png')
        || req.url.endsWith('.svg')
    ) {
        return next(req);
    }

    const localConfiguration = (environment?.applicationConfiguration ?? {}) as unknown as ApplicationConfiguration;
    const { apiUrl } = localConfiguration;
    if (apiUrl) {
        return next(req.clone({
            url: apiUrl + req.url,
        }));
    }
    return next(req);
}
