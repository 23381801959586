import {
    inject,
} from '@angular/core';
import {
    CanActivateFn,
} from '@angular/router';

import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { FeatureFlag } from '~/app/domains/configuration/enums/feature-flag.enum';

export const checkFeatureEnabledGuard: CanActivateFn = (route) => {
    const configurationFacade = inject(ConfigurationFacade);
    const isNavigationAllowed = configurationFacade.getFeatureFlagByFeatureFlagNameSnapshot(route.data?.featureFlag as FeatureFlag) ?? false;
    if (!isNavigationAllowed) {

        console.warn(`Feature guard not allow this navigation to feature ${route.data?.featureFlag}`);
    }
    return isNavigationAllowed;
};
