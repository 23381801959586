import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { ApplicationFacade } from '../../../core/state/application/application-facade/application.facade';
import { AuthenticationFacade } from '../../../core/state/authentication/authentication.facade';

export const initGuard: CanActivateFn = () => {
    const authFacade = inject(AuthenticationFacade);
    const applicationFacade = inject(ApplicationFacade);
    void authFacade.whoami();

    return applicationFacade.initApplication();
};
