@if (isChallengeRequired) {
    <eb-turnstile (resolved)="refreshChallengeToken($event)" />
}

@if (!isChallengeRequired || (authenticationFacade.challengeToken$ | async)) {
    <router-outlet />
}

@if (isLoading) {
    <eb-spinner
        class="w-100vw h-100vh d-flex justify-content-center align-items-center"
    />
}
