import { inject } from '@angular/core';
import {
    CanActivateFn,
    Router,
} from '@angular/router';

import { ApplicationFacade } from '../../../core/state/application/application-facade/application.facade';

export const initUnauthenticatedGuard: CanActivateFn = () => {
    const router = inject(Router);
    const applicationFacade = inject(ApplicationFacade);
    if (router.getCurrentNavigation()?.extras?.state?.bypassGuardAndResolver) {
        return true;
    }
    return applicationFacade.initApplication(false);
};
